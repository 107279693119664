import { UserScore } from 'Components/visual/profileScore'
import { useEffect, useState } from 'react'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'
import { sortByProperty } from 'util/sort'

export const UserPicksSummary = ({ user, selectedRound }) => {
  const { getLeaderboard, selectedLeaderboardType, LEADERBOARD_TYPES } =
    useLeaderboards()
  const [profileScore, setProfileScore] = useState(0)

  useEffect(() => {
    const leaderboard = getLeaderboard(
      selectedRound.id,
      LEADERBOARD_TYPES.picks.id
    )
    const teamPosition =
      leaderboard
        .sort((a, b) => sortByProperty(a, b, 'score', 'DESC'))
        .findIndex((entry) => entry.userId === user.id) ?? 0
    const team = leaderboard.find((entry) => entry.userId === user.id) ?? {
      score: 0,
    }

    setProfileScore({
      score: (team && team.score) ?? 0,
      position: teamPosition,
    })
  }, [
    user,
    selectedRound.id,
    getLeaderboard,
    selectedLeaderboardType,
    LEADERBOARD_TYPES,
  ])

  return <UserScore profileScore={profileScore} />
}
