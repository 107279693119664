import { ChildSpacerHAll } from 'Components/layout/ChildSpacer'
import { SurpriseIcon } from './surpriseIcon'

export const GameNameBig = ({ game }) => {
  const gameName = game?.name
  return (
    <>
      <h2
        style={{
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {gameName}
      </h2>{' '}
      <ChildSpacerHAll>
        <SurpriseIcon game={game} />
      </ChildSpacerHAll>
    </>
  )
}
