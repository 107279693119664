import { apiEndpoints } from 'showdown-api/api'
import * as React from 'react'
import { useState } from 'react'
import { buildRoundsFromIds } from 'util/round'
import { sortByProperty } from 'util/sort'
import useCachedData from 'util/useCachedData'

const LeaderboardsContext = React.createContext()

const LEADERBOARD_TYPES = {
  picks: { label: 'Picks', id: 'fantasy' },
  predictions: { label: 'Predictions', id: 'predictor' },
}

const LEADERBOARD_SORT_OPTIONS = {
  score: { label: 'Score', id: 'score' },
  scoreAvg: { label: 'Score Avg.', id: 'scoreAvg' },
}

const LEADERBOARD_FILTER = {
  friends: { label: 'Friends only', id: 'friends' },
  global: { label: 'Global', id: 'global' },
}

function LeaderboardsProvider(props) {
  const [isLoadingLeaderboards, leaderboards] = useCachedData(
    apiEndpoints.leaderboard,
    {
      entries: {},
    },
    [],
    null,
    1800000
  )

  const [selectedLeaderboardType, setSelectedLeaderboardType] = useState(
    LEADERBOARD_TYPES.picks.id
  )

  const [leaderboardsFilter, setLeaderboardsFilter] = useState(
    LEADERBOARD_FILTER.global.id
  )

  const [leaderboardsSortMode, setLeaderboardsSortMode] = useState(
    LEADERBOARD_SORT_OPTIONS.score
  )

  const getLeaderboard = (roundId, type) => {
    return leaderboards?.entries[roundId]?.[type] ?? []
  }

  const leaderboardsContext = {
    isLoadingLeaderboards,
    getLeaderboard,

    leaderboardLastUpdatedDate: leaderboards.lastUpdated,
    leaderboardRounds: buildRoundsFromIds(
      Object.keys(leaderboards.entries)
    ).sort((a, b) => sortByProperty(a, b, 'id', 'DESC')),

    LEADERBOARD_TYPES,
    selectedLeaderboardType,
    setSelectedLeaderboardType,

    LEADERBOARD_SORT_OPTIONS,
    leaderboardsSortMode,
    setLeaderboardsSortMode,

    LEADERBOARD_FILTER,
    leaderboardsFilter,
    setLeaderboardsFilter,
  }

  return <LeaderboardsContext.Provider value={leaderboardsContext} {...props} />
}

function useLeaderboards() {
  const context = React.useContext(LeaderboardsContext)
  if (!context) {
    throw new Error(
      `useLeaderboards must be used within a LeaderboardsProvider`
    )
  }
  return context
}

export { LeaderboardsProvider, useLeaderboards }
