import { Button } from '@mui/material'
import { ChildSpacerV, ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { HeroMessage } from 'Components/panes/heroMessage'
import { addDays } from 'date-fns'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRounds } from 'store/game/roundContext'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'
import { buildTodayZero, isWithinDaysAfter } from 'util/date'
import { sortByProperty } from 'util/sort'
import { TextSqueezer } from './textWrapper'

export const PredictionsWinnerMessage = () => {
  const navigate = useNavigate()

  const { mainRounds } = useRounds()
  const currentRound = mainRounds.current
  const previousRound = mainRounds.prev

  const { getLeaderboard, LEADERBOARD_TYPES } = useLeaderboards()

  const leaderboardsListData = useMemo(() => {
    const prevLeaderboards = getLeaderboard(
      previousRound.id,
      LEADERBOARD_TYPES.predictions.id
    )
    return prevLeaderboards?.sort((a, b) =>
      sortByProperty(a, b, 'score', 'DESC')
    )
  }, [getLeaderboard, previousRound, LEADERBOARD_TYPES])

  if (
    !isWithinDaysAfter(
      addDays(currentRound.startDate, 6),
      buildTodayZero(),
      4
    ) ||
    !leaderboardsListData ||
    leaderboardsListData.length < 2
  ) {
    return null
  }

  const winner = leaderboardsListData[0]
  const runnerUp = leaderboardsListData[1]

  const sharedVictory = winner.score === runnerUp.score

  return (
    <HeroMessage>
      <ChildSpacerVAll>
        <h3>{previousRound.roundName} predictions final results!</h3>
      </ChildSpacerVAll>
      <TextSqueezer>
        {sharedVictory && (
          <p>
            Ladies and gentlemen! In a rare feat <strong>{winner.name}</strong>{' '}
            and <strong>{runnerUp.name}</strong> shares the victory for{' '}
            {previousRound.roundName} at exactly {winner.score} points!
          </p>
        )}
        {!sharedVictory && (
          <p>
            Congratulations to <strong>{winner.name}</strong> who took{' '}
            {previousRound.roundName} with {winner.score} points{' '}
            {/* (new record!) */} leading by just{' '}
            {winner.score - runnerUp.score} points before runner-up{' '}
            {runnerUp.name} {/* (their best position yet!) */}.
          </p>
        )}
      </TextSqueezer>
      <ChildSpacerV>
        <Button
          variant="outlined"
          onClick={() =>
            navigate('/leaderboards/' + LEADERBOARD_TYPES.predictions.id)
          }
        >
          View predictions leaderboard
        </Button>
      </ChildSpacerV>
    </HeroMessage>
  )
}
