import { Box } from '@mui/material'
import { Text } from 'Components/visual/text'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { isGameReleased } from 'util/gameLogic'
import { sortByProperty } from 'util/sort'
import { TeamName } from '../teamName'

const SHOW_PICKS_AMOUNT = 7

export const GamePicksList = ({ game, limit }) => {
  const localLimit = limit ? limit : SHOW_PICKS_AMOUNT

  const sortedList = useMemo(() => {
    const list = game?.picks?.picks?.slice(0, limit ? limit : localLimit) ?? []
    return list.sort((a, b) => sortByProperty(a, b, 'teamName', 'ASC'))
  }, [game, localLimit, limit])

  return (
    <>
      {isGameReleased(game) && sortedList.length > 0 && (
        <>
          {sortedList.map((entry, index) => {
            return (
              <span key={index}>
                <Link
                  to={`/team/${entry.userId}`}
                  style={{
                    position: 'relative',
                    textUnderlineOffset: '5px',
                    marginRight: '8px',
                  }}
                >
                  <TeamName name={entry.teamName} teamId={entry.teamId} />
                </Link>{' '}
              </span>
            )
          })}

          {game.picks.picks.length > localLimit && (
            <Text nowrap={true}>
              +{game.picks.picks.length - localLimit} more
            </Text>
          )}

          <Box p={3} />
        </>
      )}
      {isGameReleased(game) && sortedList.length === 0 && (
        <>
          <Text>No picks were made.</Text>
          <Box p={1} />
        </>
      )}
      {!isGameReleased(game) && sortedList.length === 0 && (
        <>
          <Text>Not enough players have picked this game.</Text>
          <Box p={1} />
        </>
      )}
    </>
  )
}
