import { useParams } from 'react-router-dom'
import { useRounds } from 'store/game/roundContext'
import { InspectTeamView } from '../sidebar/inspectTeamView'

export const TeamView = () => {
  const { teamUserId } = useParams()
  const { selectedRound } = useRounds()

  return (
    <>
      <InspectTeamView userId={teamUserId} selectedRound={selectedRound} />
    </>
  )
}
