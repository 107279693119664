import { HBox, VBox } from 'Components/layout'
import { primitiveColors } from 'styles/primitiveColors'
import { predictionScoringTable } from 'util/gameLogic'

function PredictorCounter({ entry, offIndex }) {
  return (
    <VBox align="center">
      {predictionScoringTable[offIndex] + 'p'}
      <span style={{ color: primitiveColors.pColorYellow500 }}>
        {entry.scoreData.scores[`offBy${offIndex}`]}
      </span>{' '}
    </VBox>
  )
}

export const PredictorCounters = ({ entry }) => {
  return (
    <HBox style={{ whiteSpace: 'nowrap' }}>
      <PredictorCounter entry={entry} offIndex={0} />
      <PredictorCounter entry={entry} offIndex={1} />
      <PredictorCounter entry={entry} offIndex={2} />
      <PredictorCounter entry={entry} offIndex={3} />
      <PredictorCounter entry={entry} offIndex={4} />
      <PredictorCounter entry={entry} offIndex={5} />
    </HBox>
  )
}
