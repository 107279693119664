import { Box } from '@mui/material'
import { ChildSpacerHAll, ChildSpacerV } from 'Components/layout/ChildSpacer'
import { GamePicksList } from 'Components/panes/gameView/gamePicksList'
import { GamePredictionsList } from 'Components/panes/gameView/gamePredictionsList'
import { Text } from 'Components/visual/text'
import { ReleaseDate } from 'pages/home/profilePanel/releaseDate'
import { useMemo } from 'react'
import { usePredictions } from 'store/game/predictionsContext'
import { useAuth } from 'store/user/authContext'
import { getGamePredictions, getGamePredictionScore } from 'util/gameLogic'
import { GameProfile } from './gameProfile'
import { HBox, VBox } from 'Components/layout'
export const GameReviewCardInfoWide = ({ game }) => {
  const { user } = useAuth()

  const userPrediction = useMemo(
    () => getGamePredictionScore(user, game),
    [user, game]
  )

  const { isLoadingRoundGamePredictions, roundGamePredictions } =
    usePredictions()

  const gamePredictions = useMemo(() => {
    return getGamePredictions(roundGamePredictions, game.id)
  }, [roundGamePredictions, game])

  const totalPredictions = gamePredictions?.teams?.length ?? 0

  const totalPicks = game?.picks?.picks?.length ?? 0

  return (
    <VBox>
      <HBox style={{ padding: '16px', flex: 0 }}>
        <ChildSpacerHAll
          style={{
            whiteSpace: 'nowrap',
            fontSize: '12px',
            left: '16px',
            bottom: '16px',
          }}
        >
          {user.admin && <GameProfile game={game} />}
          <div>{game.tba ? 'No date' : <ReleaseDate game={game} />}</div>
        </ChildSpacerHAll>
      </HBox>
      <HBox
        style={{
          padding: '0 32px 16px 16px',
          gap: '16px',
          /* justifyContent: 'space-between', */
        }}
      >
        {!isLoadingRoundGamePredictions && (
          <div>
            <h4>
              Top predictions{' '}
              {totalPredictions > 0 && <>({totalPredictions} total)</>}
            </h4>
            <GamePredictionsList game={game} />
            <Box p={3} />
            {user.isAuthenticated &&
              !userPrediction &&
              totalPredictions > 0 && (
                <Text>You did not predict this game.</Text>
              )}
          </div>
        )}
        <Box p={3} />
        <ChildSpacerV>
          <div>
            <h4>
              Players who picked this game{' '}
              {totalPicks > 0 && <>({totalPicks} total)</>}
            </h4>
            <GamePicksList game={game} limit={20} />
          </div>
        </ChildSpacerV>
      </HBox>
    </VBox>
  )
}
