import {
  differenceInDays,
  formatDistance,
  isAfter,
  isBefore,
  isSameSecond,
} from 'date-fns'
import { format, parseISO } from 'date-fns'

export const formatISODate = (jsDate) => {
  return format(jsDate, 'yyyy-MM-dd')
}

export const isCurrentYear = (currentRound) => {
  const currentYear = buildTodayZero().getUTCFullYear()
  return parseInt(currentRound.year) === currentYear
}

export const formatShortDate = (date) => {
  return format(parseISO(date), 'MMM d')
}

export const formatLongDate = (date) => {
  return format(parseISO(date), 'MMMM d')
}

export const formatNiceDate = (date) => {
  return format(parseISO(date), 'MMMM d yyyy')
}

export const formatRelativeDate = (date) => {
  const daysDiff = differenceInDays(new Date(date), buildTodayZero())
  if (daysDiff >= 0 && daysDiff <= 1) {
    return 'Today'
  }

  return formatDistance(buildTodayZero(), parseISO(date))
}

export const isWithinWeekBefore = (date, today) => {
  const daysDiff = differenceInDays(new Date(date), today)
  return daysDiff >= 0 && daysDiff <= 6
}

export const isWithinTwoWeeksBefore = (date, today) => {
  const daysDiff = differenceInDays(date, today)
  return daysDiff >= 0 && daysDiff < 14
}

export const isWithinWeekAfter = (date, today) => {
  const daysDiff = differenceInDays(today, date)
  return daysDiff >= 0 && daysDiff <= 6
}

export const isWithinDaysAfter = (date, today, days) => {
  const daysDiff = differenceInDays(today, date)
  return daysDiff >= 0 && daysDiff <= days
}

export const buildDateZeroTime = (date) => {
  return new Date(`${date}T00:00:00`)
}

export const buildDateMaxTime = (date) => {
  return new Date(`${date}T23:59:59`)
}

export const buildTodayZero = () => {
  const dateString = formatISODate(new Date())
  return new Date(`${dateString}T00:00:00`)
}

export const isSameOrBefore = (
  d1 = buildTodayZero(),
  d2 = buildTodayZero()
) => {
  return isSameSecond(d1, d2) ? true : isBefore(d1, d2) ? true : false
}

export const isSameOrAfter = (d1 = buildTodayZero(), d2 = buildTodayZero()) => {
  return isSameSecond(d1, d2) ? true : isAfter(d1, d2) ? true : false
}

export const isWithin = (date, startDate, endDate) => {
  return (
    isSameOrAfter(new Date(date), new Date(startDate)) &&
    isSameOrBefore(new Date(date), new Date(endDate))
  )
}

export const daysLeft = (date) => {
  return differenceInDays(buildDateZeroTime(date), buildTodayZero())
}
