import { Box, Button, CircularProgress, useMediaQuery } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useTheme } from '@mui/material/styles'
import { UserFriends } from 'Components/panes/userFriends'
import { UserWatchlist } from 'Components/panes/userWatchlist'
import * as React from 'react'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { useFriends } from 'store/game/friendsContext'
import { useGames } from 'store/game/gameContext'
import { useRounds } from 'store/game/roundContext'
import { useAuth } from 'store/user/authContext'
import { query } from 'styles/mediaQueries'
import { semanticSurface } from 'styles/semanticSurface'
import { sortByProperty } from 'util/sort'
import { InspectTeamPredictionsView } from '../sidebar/inspectTeamPredictionsView'
import { HBox } from './../../../Components/layout/HBox'
import { VBox } from './../../../Components/layout/VBox'
import { UserPanelViewHeader } from './userPanelViewHeader'
import { UserPicksSummary } from './userPicksSummary'
import { YourGamesList } from './yourGamesList'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

export const UserPanelView = () => {
  const { user, isUserLoading, signOut } = useAuth()
  const { tab } = useParams()
  const navigate = useNavigate()

  const isMobile = useMediaQuery(query.mobile)

  const { selectedRound, isGameReleasedInRound } = useRounds()

  const theme = useTheme()
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)

  React.useEffect(() => {
    switch (tab) {
      case 'picks':
        setSelectedTabIndex(0)
        break
      case 'predictions':
        setSelectedTabIndex(1)
        break
      case 'watchlist':
        setSelectedTabIndex(2)
        break
      case 'friends':
        setSelectedTabIndex(3)
        break
      default:
        setSelectedTabIndex(0)
        break
    }
  }, [tab])

  const handleChange = (event, newValue) => {
    navigate(
      `/team-manage/${
        newValue === 0
          ? 'picks'
          : newValue === 1
          ? 'predictions'
          : newValue === 2
          ? 'watchlist'
          : 'friends'
      }`
    )
  }

  const handleChangeIndex = (index) => {
    /*     navigate(
      `/team-manage/${
        index === 0
          ? 'picks'
          : index === 1
          ? 'predictions'
          : index === 2
          ? 'watchlist'
          : 'friends'
      }`
    )
 */
    setSelectedTabIndex(index)
  }

  /// From UserPredictionsList.js. Maybe move to a custom hook?
  const {
    isLoadingUserGames,
    availableGames,
    watchedGames,
    isLoadingWatchedGames,
  } = useGames()

  const userWatchlist = useMemo(() => {
    let gamesMap = {}
    availableGames?.forEach((game) => {
      gamesMap[game.id] = game
    })

    return watchedGames
      .map((watchedGame) => {
        const game = gamesMap[watchedGame.id]

        if (!isGameReleasedInRound(game, selectedRound)) {
          return false
        }

        return {
          ...game,
          watched: watchedGame.watched,
        }
      })
      .filter((game) => game !== false)
      .sort((a, b) => sortByProperty(a, b, 'prediction', 'DESC'))
      .slice(0, 50)
  }, [availableGames, watchedGames, selectedRound, isGameReleasedInRound])

  const { friends } = useFriends()
  const friendRequests = useMemo(
    () =>
      friends?.filter((friend) => friend.status === 'REQUEST_RECEIVED') ?? [],
    [friends]
  )

  const friendsTabLabel =
    friendRequests.length > 0 ? `Friends (${friendRequests.length})` : 'Friends'

  return (
    <>
      <UserPanelViewHeader team={user.team} />
      <div>
        <Tabs
          value={selectedTabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="user panel tabs"
          style={{ maxWidth: '600px' }}
        >
          <Tab label="Picks" {...a11yProps(0)} />
          <Tab label="Predict" {...a11yProps(1)} />
          <Tab label="Watchlist" {...a11yProps(1)} />
          <Tab label={friendsTabLabel} {...a11yProps(1)} />
        </Tabs>
        <div
          style={{
            width: '100%',
            height: '1px',
            marginTop: '-1px',
            backgroundColor: semanticSurface.surfaceLayerHigh,
          }}
        ></div>
      </div>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={selectedTabIndex}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={selectedTabIndex} index={0} dir={theme.direction}>
          <Box p={4} />
          <UserPicksSummary user={user} selectedRound={selectedRound} />
          <Box p={4} />
          {isLoadingUserGames && (
            <>
              <Box p={2} />
              <HBox align="center">
                <VBox align="center">
                  <CircularProgress />
                  <Box p={1} />
                  Loading your game picks
                </VBox>
              </HBox>
            </>
          )}
          {!isLoadingUserGames && <YourGamesList />}
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={1} dir={theme.direction}>
          <Box p={4} />
          <InspectTeamPredictionsView
            team={user.team}
            teamUserId={user.id}
            isLoadingTeam={isUserLoading}
            selectedRound={selectedRound}
          />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={2} dir={theme.direction}>
          <Box p={4} />
          <UserWatchlist
            watchlist={userWatchlist}
            isLoading={isLoadingWatchedGames}
          />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={3} dir={theme.direction}>
          <Box p={4} />
          <UserFriends />
        </TabPanel>
      </SwipeableViews>
      <Box p={4} />
      <div
        style={{
          borderTop: `1px solid ${semanticSurface.surfaceLayerLow}`,
          width: '100%',
          height: '1px',
        }}
      ></div>
      <Box p={4} />
      <h3>Personal stats</h3>
      <Box p={3} />
      <h4>Picks mode</h4>
      <div>Personal best: {user.team.picksPersonalBest}</div>
      <div>Total wins: {user.team.picksWins}</div>
      <div>Best record score: {user.team.picksRecordScore}</div>
      <Box p={3} />
      <h4>Predictions mode</h4>
      <div>Personal best: {user.team.predictorPersonalBest}</div>
      <div>Total wins: {user.team.predictorWins}</div>
      <div>Best record score: {user.team.predictorRecordScore}</div>

      {isMobile && (
        <>
          <Box p={4} />
          <div
            style={{
              borderTop: `1px solid ${semanticSurface.surfaceLayerLow}`,
              width: '100%',
              height: '1px',
            }}
          ></div>
          <Box p={4} />
          <Button
            variant="outlined"
            style={{ width: '100%' }}
            onClick={signOut}
          >
            Sign out
          </Button>
        </>
      )}
    </>
  )
}
