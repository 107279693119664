import { useMediaQuery } from '@mui/material'
import { HBox, VBox } from 'Components/layout'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { DarkList } from 'Components/visual/darkList'
import { WinnerCrown } from 'pages/home/profilePanel/WinnerCrown'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'
import { query } from 'styles/mediaQueries'
import { primitiveColors } from 'styles/primitiveColors'
import { roundPrecise } from 'util/math'
import BronzeMedal from './Bronze.svg'
import GoldMedal from './Gold.svg'
import SilverMedal from './Silver.svg'
import { LeaderboardPlace } from './leaderboardPlaceNumber'
import { transformPredictorLeaderboardList } from './leaderboardsDataTransform'
import { TeamName } from './teamName'
import { PredictorCounters } from 'pages/home/profilePanel/predictorCounters'

export const PredictorLeaderboardList = ({ leaderboardList, label, mode }) => {
  const { isLoadingLeaderboards, leaderboardsSortMode } = useLeaderboards()
  const leaderboardsListData = useMemo(
    () =>
      transformPredictorLeaderboardList(
        leaderboardList,
        mode,
        leaderboardsSortMode
      ),
    [leaderboardList, mode, leaderboardsSortMode]
  )

  return (
    <VBox>
      <ChildSpacerVAll>
        <PredictorLeaderboardRenderer
          label={
            typeof label !== 'undefined' ? label : 'Predictions leaderboards'
          }
          leaderboardsListData={leaderboardsListData}
          isLoadingLeaderboards={isLoadingLeaderboards}
          mode={mode}
        />
      </ChildSpacerVAll>
    </VBox>
  )
}

const PredictorLeaderboardRenderer = ({
  label,
  leaderboardsListData,
  isLoadingLeaderboards,
}) => {
  const navigate = useNavigate()

  const medals = [
    <img src={GoldMedal} style={{ width: '40px' }} alt="Gold medal" />,
    <img src={SilverMedal} style={{ width: '40px' }} alt="Silver medal" />,
    <img src={BronzeMedal} style={{ width: '40px' }} alt="Bronze medal" />,
  ]

  const columns = [
    {
      flex: 1,
      align: 'center',
      ColComponent: ({ colData }) => {
        return (
          <>
            {colData?.entry?.place <= 3 &&
            colData.entry.scoreData.totalScore !== 0 ? (
              medals[colData?.entry?.place - 1]
            ) : (
              <LeaderboardPlace place={colData?.entry?.place} />
            )}
          </>
        )
      },
    },
    {
      flex: 9,
      ColComponent: ({ colData }) => {
        return (
          <VBox>
            {colData.entry.scoreData.totalScore >= 0 && (
              <>
                <HBox style={{ position: 'relative' }}>
                  <TeamName
                    name={colData.entry.name}
                    teamId={colData.entry.teamId}
                  />{' '}
                  <WinnerCrown entry={colData.entry} />
                </HBox>
                <SubtleText
                  text={`${colData.entry.scoreData.totalPredicted} predictions`}
                />
              </>
            )}
          </VBox>
        )
      },
      align: 'left',
    },

    {
      ColComponent: ({ colData }) => {
        return <PredictorCounters entry={colData.entry} />
      },
    },
    {
      ColComponent: ({ colData }) => {
        return (
          <>
            {colData.entry.scoreData.totalScore > 0 && (
              <VBox
                style={{
                  fontSize: '19px',
                  fontWeight: 'bold',
                  color: primitiveColors.pColorYellow500,
                }}
              >
                {colData.entry.scoreData.totalScore}p
                <SubtleText
                  text={`${
                    colData.entry.scoreData.totalScored
                  } scored (Avg. ${roundPrecise(
                    colData.entry.scoreData.totalScore /
                      colData.entry.scoreData.totalScored,
                    1
                  )}p)`}
                />
              </VBox>
            )}
          </>
        )
      },
    },
  ]

  const isMobile = useMediaQuery(query.mobile)

  return (
    <>
      <DarkList
        label={label}
        headerButtonLabel={typeof label !== 'undefined' && 'View leaderboards'}
        list={leaderboardsListData}
        emptyText="No profiles signed up or predicted any games for the presented round yet."
        columns={columns}
        onRowClick={(rowData) => navigate('/teamPredictions/' + rowData.userId)}
        rowPadding={isMobile ? '16px' : '16px 16px 16px 0'}
        isLoading={isLoadingLeaderboards}
      ></DarkList>
    </>
  )
}
