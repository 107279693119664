import { Box } from '@mui/material'
import { isBefore } from 'date-fns'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useRounds } from 'store/game/roundContext'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'
import { RenderLeaderboards } from './renderLeaderboards'
import { LeaderboardsFilter } from 'Components/game/leaderboardsFilter'

export const LeaderboardsContent = () => {
  const { setSelectedLeaderboardType, LEADERBOARD_TYPES } = useLeaderboards()
  const { selectedBoard } = useParams()

  useEffect(() => {
    if (selectedBoard === LEADERBOARD_TYPES.predictions.id) {
      setSelectedLeaderboardType(selectedBoard)
    } else {
      setSelectedLeaderboardType(LEADERBOARD_TYPES.picks.id)
    }
  }, [selectedBoard, setSelectedLeaderboardType, LEADERBOARD_TYPES])

  const { mainRounds, selectedRound } = useRounds()

  let pageTitle = useMemo(() => {
    let pageTitle = 'Standings'
    if (isBefore(selectedRound.endDate, mainRounds.current.startDate)) {
      pageTitle = 'Final standings'
    }

    return pageTitle
  }, [mainRounds, selectedRound])

  return (
    <>
      <h2>{pageTitle}</h2>
      <Box p={2} />
      <LeaderboardsFilter />
      <Box p={4} />
      <RenderLeaderboards />
    </>
  )
}
