import { Box, useMediaQuery } from '@mui/material'
import { HBox, VBox } from 'Components/layout'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { DarkList } from 'Components/visual/darkList'
import { WinnerCrown } from 'pages/home/profilePanel/WinnerCrown'
import { BadgeCounters } from 'pages/home/profilePanel/badgeCounters'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Breakpoint } from 'react-socks'
import { useGames } from 'store/game/gameContext'
import { useRounds } from 'store/game/roundContext'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'
import { useAuth } from 'store/user/authContext'
import { query } from 'styles/mediaQueries'
import BronzeMedal from './Bronze.svg'
import GoldMedal from './Gold.svg'
import SilverMedal from './Silver.svg'
import { LeaderboardPlace } from './leaderboardPlaceNumber'
import { transformFantasyLeaderboardList } from './leaderboardsDataTransform'
import { TeamName } from './teamName'

export const FantasyLeaderboardList = ({ leaderboardList, label, mode }) => {
  const { user } = useAuth()

  const { isLoadingLeaderboards, leaderboardsSortMode } = useLeaderboards()
  const { selectedGames } = useGames()
  const { mainRounds, selectedRound } = useRounds()

  const leaderboardsListData = useMemo(
    () =>
      transformFantasyLeaderboardList(
        leaderboardList,
        mode,
        leaderboardsSortMode,
        user,
        mainRounds?.current?.id === selectedRound?.id,
        selectedGames
      ),
    [
      leaderboardList,
      mode,
      leaderboardsSortMode,
      selectedRound,
      user,
      mainRounds,
      selectedGames,
    ]
  )

  return (
    <VBox>
      <ChildSpacerVAll>
        <FantasyLeaderboardRenderer
          label={typeof label !== 'undefined' ? label : 'Picks standings'}
          leaderboardsListData={leaderboardsListData}
          isLoadingLeaderboards={isLoadingLeaderboards}
          mode={mode}
        />
      </ChildSpacerVAll>
    </VBox>
  )
}

const FantasyLeaderboardRenderer = ({
  label,
  leaderboardsListData,
  isLoadingLeaderboards,
  mode,
}) => {
  const navigate = useNavigate()

  const medals = [
    <img src={GoldMedal} style={{ width: '40px' }} alt="Gold medal" />,
    <img src={SilverMedal} style={{ width: '40px' }} alt="Silver medal" />,
    <img src={BronzeMedal} style={{ width: '40px' }} alt="Bronze medal" />,
  ]

  const columns = [
    {
      flex: 1,
      align: 'center',
      ColComponent: ({ colData }) => {
        return (
          <>
            {colData?.entry?.place <= 3 && colData.entry.score !== 0 ? (
              medals[colData?.entry?.place - 1]
            ) : (
              <LeaderboardPlace place={colData?.entry?.place} />
            )}
          </>
        )
      },
    },
    {
      flex: 6,
      ColComponent: ({ colData }) => {
        return (
          <VBox>
            {colData.entry.score >= 0 && (
              <>
                <HBox style={{ position: 'relative' }}>
                  <TeamName
                    name={colData.entry.name}
                    teamId={colData.entry.teamId}
                  />{' '}
                  <WinnerCrown entry={colData.entry} />
                </HBox>
                <SubtleText text={colData.entry.scoredPickedGameCount} />
              </>
            )}
          </VBox>
        )
      },
      align: 'left',
    },
    {
      isVisibleFn: ({ entry }) => mode !== 'compact',
      ColComponent: ({ colData }) => {
        return (
          <>
            {colData.entry.score > 0 && colData.entry.badges && (
              <VBox>
                <Breakpoint customQuery={query.mobile}>
                  <Box p={1} />
                  <BadgeCounters badges={colData.entry.badges} />
                </Breakpoint>
                <Breakpoint customQuery={query.mobileUp}>
                  <BadgeCounters badges={colData.entry.badges} />
                </Breakpoint>
              </VBox>
            )}
          </>
        )
      },
    },
    {
      isVisibleFn: ({ entry }) => mode !== 'compact',
      ColComponent: ({ colData }) => {
        return (
          <>
            {colData.entry.score > 0 && (
              <VBox>
                <Breakpoint customQuery={query.mobile}>
                  {colData.entry.scoreAvg > 0 &&
                    `Avg. ${colData.entry.scoreAvg}`}
                  {colData.entry.scoreAvg === 0 && (
                    <SubtleText>
                      Score {3 - colData.entry.scoredGameCount} more for point
                      average
                    </SubtleText>
                  )}
                </Breakpoint>
                <Breakpoint customQuery={query.mobileUp}>
                  {colData.entry.scoreAvg > 0 && (
                    <>
                      {colData.entry.scoreAvg}
                      <SubtleText text="Avg." />
                    </>
                  )}
                  {colData.entry.scoreAvg === 0 && (
                    <SubtleText>
                      Score {3 - colData.entry.scoredGameCount} more
                    </SubtleText>
                  )}
                </Breakpoint>
              </VBox>
            )}
          </>
        )
      },
    },
    {
      ColComponent: ({ colData }) => {
        return (
          <>
            {colData.entry.score > 0 && (
              <span
                style={{
                  fontSize: '19px',
                  fontWeight: 'bold',
                }}
              >
                {colData.entry.score}
                {colData.entry.scoreProjected > 0 &&
                  colData.entry.scoredGameCount < 9 && (
                    <SubtleText text={'~' + colData.entry.scoreProjected} />
                  )}
              </span>
            )}
          </>
        )
      },
    },
  ]

  const isMobile = useMediaQuery(query.mobile)

  return (
    <>
      <DarkList
        label={label}
        headerButtonLabel={typeof label !== 'undefined' && 'View leaderboards'}
        onButtonClick={() => navigate('/leaderboards')}
        list={leaderboardsListData}
        emptyText="No profiles signed up or picked any games for the presented round yet."
        columns={columns}
        onRowClick={(rowData) => navigate('/team/' + rowData.userId)}
        rowPadding={isMobile ? '16px' : '16px 16px 16px 0'}
        isLoading={isLoadingLeaderboards}
      ></DarkList>
    </>
  )
}
