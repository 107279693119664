import { UserScore } from 'Components/visual/profileScore'
import { useEffect, useState } from 'react'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'
import { sortByProperty } from 'util/sort'

export const UserPredictionsSummary = ({ user, selectedRound }) => {
  const { getLeaderboard, LEADERBOARD_TYPES } = useLeaderboards()
  const [profileScore, setProfileScore] = useState({
    score: 0,
    position: 9999,
  })

  useEffect(() => {
    const leaderboard = getLeaderboard(
      selectedRound.id,
      LEADERBOARD_TYPES.predictions.id
    )
    if (!leaderboard) {
      return
    }

    const teamPosition =
      leaderboard
        ?.sort((a, b) =>
          sortByProperty(a.scoreData, b.scoreData, 'totalScore', 'DESC')
        )
        .findIndex((entry) => entry.userId === user.id) ?? 0
    const team = leaderboard.find((entry) => entry.userId === user.id) ?? {
      scoreData: {
        totalScore: 0,
      },
    }

    setProfileScore({
      score: (team && team.scoreData.totalScore) ?? 0,
      position: teamPosition,
    })
  }, [user, getLeaderboard, selectedRound.id, LEADERBOARD_TYPES.predictions.id])

  return <UserScore profileScore={profileScore} />
}
