import { Box, Button } from '@mui/material'
import { HBox, VBox } from 'Components/layout'
import { ScoreBadge } from 'Components/visual/scoreBadge/scoreBadge'
import { SubtleText } from 'Components/visual/SubtleText'
import { useNavigate } from 'react-router-dom'
import { colors } from 'styles/colors'
import { fontSize } from 'styles/fontSize'
import { semanticSurface } from 'styles/semanticSurface'
import { GameCardImage } from './gameCardImage'
import { GameName } from './gameName'
import { GameReviewCardInfo } from './gameReviewCardInfo'
import { semanticMedia } from 'styles/semanticMedia'
import { Breakpoint } from 'react-socks'
import { query } from 'styles/mediaQueries'
import { GameReviewCardInfoWide } from './gameReviewCardInfoWide'

export const GameReviewCard = ({ game }) => {
  const navigate = useNavigate()

  const picksList = game?.picks?.picks?.slice(0, 3) ?? []

  return (
    <>
      <Breakpoint customQuery={query.mobile}>
        <div
          style={{
            border: game.selected ? `2px solid ${colors.theme}` : 'none',
            margin: game.selected ? '-1 0 0 -1' : '0',
            overflow: 'hidden',

            borderRadius: 16,
            backgroundColor: semanticSurface.surfaceLayerLow,
          }}
        >
          <Button
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: 0,

              boxShadow: '2px 2px 0 rgba(0,0,0,0.1)',
              borderRadius: 16,
            }}
            sx={{
              transition: 'background-color 0.2s',
              backgroundColor: semanticSurface.surfaceLayerMedium,
              '&:hover': {
                backgroundColor: semanticSurface.surfaceLayerHigh,
              },
            }}
            onClick={() => navigate('/game/' + game.slug)}
          >
            <GameCardImage
              game={game}
              position="relative"
              isHoverEffectActive={false}
            />

            <div
              style={{
                fontSize: '16px',
                padding: '8px 16px',
                width: '100%',
                textAlign: 'left',
              }}
            >
              <GameName game={game} />{' '}
              {game?.category !== 'Full game' && (
                <SubtleText>
                  {game.category === 'Full game' || game.category === null
                    ? ''
                    : game.category}
                </SubtleText>
              )}
              <Box p={2} />
              {game.metacritic && (
                <VBox
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',

                    borderRadius: 12,
                    padding: '8px 32px',
                  }}
                >
                  <VBox align="center">
                    <span>
                      <ScoreBadge
                        game={game}
                        disablePrediction={true}
                        text="Current review score. May change until the end of the round as more reviews drop."
                      />
                    </span>
                  </VBox>
                  {picksList.length > 0 && (
                    <HBox align="center">
                      <span
                        className="material-symbols-rounded"
                        style={{
                          color: semanticMedia.contrastLow,
                        }}
                      >
                        how_to_reg
                      </span>
                      <Box p={1} />
                      {game.picks?.pickedPercent > 0 && (
                        <>
                          <span
                            style={{
                              fontSize: fontSize.large,
                              color: semanticMedia.contrastHigh,
                            }}
                          >
                            {game.picks?.pickedPercent}%
                          </span>
                        </>
                      )}
                    </HBox>
                  )}
                </VBox>
              )}
              <Box p={1} />
              <span
                style={{
                  position: 'absolute',
                  right: 16,
                  top: 16,
                  color: colors.theme,
                }}
              >
                {game.selected && (
                  <span className="material-symbols-rounded">check_circle</span>
                )}
              </span>
            </div>
          </Button>

          <GameReviewCardInfo game={game} />
        </div>
      </Breakpoint>
      <Breakpoint customQuery={query.mobileLandscapeUp}>
        <div
          style={{
            border: game.selected ? `2px solid ${colors.theme}` : 'none',
            margin: game.selected ? '-1 0 0 -1' : '0',
            overflow: 'hidden',

            borderRadius: 16,
            backgroundColor: semanticSurface.surfaceLayerLow,

            display: 'flex',
            flexDirection: 'row',
            maxWidth: '1000px',
          }}
        >
          <Button
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              padding: 0,

              boxShadow: '2px 2px 0 rgba(0,0,0,0.1)',
              borderRadius: '16px 0 0 16px',
              overflow: 'hidden',
              maxWidth: '300px',
            }}
            sx={{
              transition: 'background-color 0.2s',
              backgroundColor: semanticSurface.surfaceLayerMedium,
              '&:hover': {
                backgroundColor: semanticSurface.surfaceLayerHigh,
              },
            }}
            onClick={() => navigate('/game/' + game.slug)}
          >
            <GameCardImage
              game={game}
              position="relative"
              isHoverEffectActive={false}
            />

            <div
              style={{
                fontSize: '16px',
                padding: '8px 16px',
                width: '100%',
                textAlign: 'left',
              }}
            >
              <GameName game={game} />{' '}
              {game?.category !== 'Full game' && (
                <SubtleText>
                  {game.category === 'Full game' || game.category === null
                    ? ''
                    : game.category}
                </SubtleText>
              )}
              <Box p={2} />
              {game.metacritic && (
                <VBox
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',

                    borderRadius: 12,
                    padding: '8px 32px',
                  }}
                >
                  <VBox align="center">
                    <span>
                      <ScoreBadge
                        game={game}
                        disablePrediction={true}
                        text="Current review score. May change until the end of the round as more reviews drop."
                      />
                    </span>
                  </VBox>
                  {picksList.length > 0 && (
                    <HBox align="center">
                      <span
                        className="material-symbols-rounded"
                        style={{
                          color: semanticMedia.contrastLow,
                        }}
                      >
                        how_to_reg
                      </span>
                      <Box p={1} />
                      {game.picks?.pickedPercent > 0 && (
                        <>
                          <span
                            style={{
                              fontSize: fontSize.large,
                              color: semanticMedia.contrastHigh,
                            }}
                          >
                            {game.picks?.pickedPercent}%
                          </span>
                        </>
                      )}
                    </HBox>
                  )}
                </VBox>
              )}
              <Box p={1} />
              <span
                style={{
                  position: 'absolute',
                  right: 16,
                  top: 16,
                  color: colors.theme,
                }}
              >
                {game.selected && (
                  <span className="material-symbols-rounded">check_circle</span>
                )}
              </span>
            </div>
          </Button>

          <GameReviewCardInfoWide game={game} />
        </div>
      </Breakpoint>
    </>
  )
}
