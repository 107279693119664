import { ChildSpacerHAll } from 'Components/layout/ChildSpacer'
import { SurpriseIcon } from './surpriseIcon'
import { semanticMedia } from 'styles/semanticMedia'
import { primitiveColors } from 'styles/primitiveColors'

export const GameName = ({ game, mark = false }) => {
  const gameName = game?.name ?? 'Invalid name'

  return (
    <span
      style={{
        display: 'block',
        position: 'relative',
        height: '28px',
        width: '100%',
      }}
    >
      <ChildSpacerHAll style={{ alignItems: 'center' }}>
        <SurpriseIcon game={game} />
      </ChildSpacerHAll>
      <span
        style={{
          position:
            'absolute' /* don't know why but this is necessary for ellipsis */,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          width: '100%',
          color: mark
            ? primitiveColors.pColorYellow500
            : semanticMedia.contrastHigh,
        }}
      >
        {gameName}
      </span>
    </span>
  )
}
