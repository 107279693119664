import { Box, Grid, Skeleton } from '@mui/material'
import { CSButton } from 'Components/game/CSButton'
import { RoundCardButton } from 'Components/game/RoundCardButton'
import { GameNameBig } from 'Components/game/gameNameBig'
import { GameProfile } from 'Components/game/gameProfile'
import { PickDropButton } from 'Components/game/pickDropButton'
import { WatchlistButton } from 'Components/game/watchlistButton'
import { ExternalLink } from 'Components/interactive/ExternalLink'
import { VBox } from 'Components/layout'
import { ChildSpacerH } from 'Components/layout/ChildSpacer'
import { GameAdminControls } from 'Components/panes/gameView/gameAdminControls'
import { GamePicksList } from 'Components/panes/gameView/gamePicksList'
import { GamePredictionsList } from 'Components/panes/gameView/gamePredictionsList'
import { GameViewMobile } from 'Components/panes/gameView/gameViewMobile'
import { InvolvedCompanies } from 'Components/panes/gameView/involvedCompanies'
import { MultiplayerModes } from 'Components/panes/gameView/multiplayerModes'
import { ReadMore } from 'Components/panes/gameView/readMore'
import { UserPredictGame } from 'Components/panes/gameView/userPredictGame'
import { HLine } from 'Components/visual/HLine'
import { SubtleText } from 'Components/visual/SubtleText'
import { WordDotList } from 'Components/visual/WordDotList'
import { ScoreBadge } from 'Components/visual/scoreBadge/scoreBadge'
import { Text } from 'Components/visual/text'
import { formatDistance } from 'date-fns'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Breakpoint } from 'react-socks'
import { apiEndpoints } from 'showdown-api/api'
import { useGames } from 'store/game/gameContext'
import { useGameFilter } from 'store/game/gameFilterContext'
import { usePredictions } from 'store/game/predictionsContext'
import { useAuth } from 'store/user/authContext'
import { colors } from 'styles/colors'
import { fontSize } from 'styles/fontSize'
import { query } from 'styles/mediaQueries'
import { semanticSurface } from 'styles/semanticSurface'
import { buildTodayZero } from 'util/date'
import {
  getGamePredictions,
  isDropAfterReleaseAllowed,
  isDropAllowed,
  isGameReleased,
  isGameSelected,
} from 'util/gameLogic'
import { buildIgdbImageLink } from 'util/image'
import useCachedData from 'util/useCachedData'
import { Platforms } from './platforms'

export const GameView = () => {
  const { slug } = useParams()
  const {
    watchedGames,
    selectedGames,
    selectedGamesOutsideRound,
    calcGameProfilePoints,
    isLoadingAvailableGames,
    gamePicks,
  } = useGames()

  const { filteredSelectedGames } = useGameFilter()

  const nextGameSlug = useMemo(() => {
    let currentGameIndex = filteredSelectedGames.findIndex(
      (game) => game.slug === slug
    )
    let nextGame = filteredSelectedGames[currentGameIndex + 1] ?? null
    return nextGame?.slug
  }, [filteredSelectedGames, slug])

  const [isLoadingGame, viewedGame] = useCachedData(
    apiEndpoints.game + '/' + slug,
    null,
    [slug]
  )

  const navigate = useNavigate()

  const { user } = useAuth()

  const cachedSelectedGame = useMemo(() => {
    if (!viewedGame) {
      return null
    }

    let watchedGame = watchedGames.find((game) => game.id === viewedGame.id)

    let picks = null
    if (gamePicks?.gamesPicked?.hasOwnProperty(viewedGame.id)) {
      if (
        isGameReleased(viewedGame) ||
        gamePicks?.gamesPicked[viewedGame.id]?.picks?.length > 3
      ) {
        picks = gamePicks?.gamesPicked[viewedGame.id]
      }
    }

    return {
      ...viewedGame,
      profilePoints: calcGameProfilePoints(viewedGame),
      picks,
      selected: isGameSelected(
        viewedGame,
        selectedGames,
        selectedGamesOutsideRound
      ),
      watched: !!watchedGame,
      dropAllowed: isDropAllowed(viewedGame),
      dropAfterReleaseAllowed: isDropAfterReleaseAllowed(viewedGame),
    }
  }, [
    viewedGame,
    watchedGames,
    gamePicks,
    selectedGames,
    selectedGamesOutsideRound,
    calcGameProfilePoints,
  ])

  const { roundGamePredictions } = usePredictions()

  const gamePredictions = useMemo(() => {
    return getGamePredictions(roundGamePredictions, cachedSelectedGame?.id)
  }, [roundGamePredictions, cachedSelectedGame])

  const totalPredictions = gamePredictions?.teams?.length ?? 0

  if (isLoadingGame) {
    return (
      <>
        <Skeleton
          style={{
            backgroundColor: semanticSurface.surfaceLayerLow,
            height: '300px',
            minWidth: '30%',
            transform: 'none',
          }}
          key={'skeletonGameView'}
        />
      </>
    )
  }

  if (!cachedSelectedGame) {
    return <p>Game not found.</p>
  }

  const totalPicks = cachedSelectedGame?.picks?.picks.length ?? 0

  return (
    <div style={{ width: '100%', marginBottom: '64px' }}>
      <Breakpoint customQuery={query.tabletDown}>
        <GameViewMobile
          selectedGame={cachedSelectedGame}
          nextGameSlug={nextGameSlug}
        />
      </Breakpoint>
      <Breakpoint customQuery={query.tabletUp}>
        <ChildSpacerH
          style={{ justifyContent: 'space-between', alignItems: 'end' }}
        >
          <GameNameBig game={cachedSelectedGame} />
          <ChildSpacerH>
            <WatchlistButton
              game={cachedSelectedGame}
              ButtonComponent={RoundCardButton}
            />
            <PickDropButton
              game={cachedSelectedGame}
              ButtonComponent={CSButton}
            />
            {(isLoadingAvailableGames || nextGameSlug) && (
              <CSButton
                saving={isLoadingAvailableGames}
                onClick={(e) => {
                  navigate(`/game/${nextGameSlug}`)
                }}
                label="Next game in current filter"
                endIconName="arrow_forward"
              ></CSButton>
            )}
          </ChildSpacerH>
        </ChildSpacerH>
        {cachedSelectedGame.category !== 'Full game' && (
          <SubtleText>{cachedSelectedGame.category}</SubtleText>
        )}
        <Box p={3}></Box>
        {cachedSelectedGame?.videos?.length > 0 &&
          cachedSelectedGame?.videos[0] && (
            <div>
              <iframe
                title="Game Video"
                width="100%"
                height="475"
                src={`https://www.youtube.com/embed/${cachedSelectedGame.videos[0].video_id}?controls=1&autoplay=0&mute=1&loop=1&playlist=${viewedGame.videos[0].video_id}`}
                frameBorder="0"
              ></iframe>
            </div>
          )}
        <Box p={3}></Box>

        {cachedSelectedGame.metacritic && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: colors.darkPurple,
              borderRadius: 12,
              padding: '16px 0',
            }}
          >
            <VBox align="center">
              <span>
                <ScoreBadge
                  game={cachedSelectedGame}
                  disablePrediction={true}
                  text="Current review score. May change until the end of the round as more reviews drop."
                />
              </span>
              <Text>
                Current score (
                {cachedSelectedGame.aggregated_rating_count && (
                  <Text>
                    {cachedSelectedGame.aggregated_rating_count}{' '}
                    {cachedSelectedGame.aggregated_rating_count === 1
                      ? 'source'
                      : 'sources'}
                  </Text>
                )}
                {!cachedSelectedGame.aggregated_rating_count && (
                  <Text>Manually added by moderators</Text>
                )}
                )
              </Text>
            </VBox>

            {cachedSelectedGame?.picks?.picks?.length > 0 && (
              <VBox align="center">
                {cachedSelectedGame.picks?.pickedPercent > 0 && (
                  <>
                    <span style={{ fontSize: fontSize.large }}>
                      {cachedSelectedGame.picks?.pickedPercent}%
                    </span>
                  </>
                )}
                <Text>Pick rate</Text>
              </VBox>
            )}
          </div>
        )}

        <Box p={1}></Box>
        <GameProfile game={cachedSelectedGame} showLabel={true} />
        <Box p={3}></Box>
        <Grid container spacing={7}>
          <Grid item xs={7}>
            <div>
              <h4>Release date</h4>
              <div>{cachedSelectedGame.released}</div>
            </div>
            <Box p={4}></Box>
            <div>
              <h4>Platforms</h4>
              <Platforms platforms={cachedSelectedGame.platforms} />
            </div>
            <Box p={4}></Box>
            <div>
              <InvolvedCompanies list={cachedSelectedGame.involved_companies} />
            </div>
            <Box p={4}></Box>
            {cachedSelectedGame.summary && (
              <>
                <div>
                  <h4>Summary</h4>
                  <ReadMore id="summary" text={cachedSelectedGame.summary} />
                </div>
                <Box p={4}></Box>
              </>
            )}

            {(cachedSelectedGame?.genres ||
              cachedSelectedGame?.themes?.length > 0) && (
              <>
                <div>
                  <h4>Genres & themes</h4>
                  {cachedSelectedGame?.genres && (
                    <div>
                      <WordDotList list={cachedSelectedGame?.genres} />
                    </div>
                  )}
                  {cachedSelectedGame?.themes?.length > 0 && (
                    <div>
                      <WordDotList list={cachedSelectedGame?.themes} />
                    </div>
                  )}
                </div>
                <Box p={4}></Box>
              </>
            )}
            {(cachedSelectedGame.player_perspectives ||
              cachedSelectedGame.game_modes ||
              cachedSelectedGame.multiplayer_modes) && (
              <>
                <div>
                  <h4>Gameplay</h4>
                  <div>
                    <WordDotList
                      list={cachedSelectedGame.player_perspectives}
                    />
                  </div>
                  <div>
                    <WordDotList list={cachedSelectedGame.game_modes} />
                  </div>
                  <MultiplayerModes
                    modes={cachedSelectedGame.multiplayer_modes}
                  />
                </div>
                <Box p={4}></Box>
              </>
            )}
            {cachedSelectedGame.storyline && (
              <>
                <div>
                  <h4>Storyline</h4>
                  <ReadMore id="summary" text={cachedSelectedGame.storyline} />
                </div>
                <Box p={4}></Box>
              </>
            )}
            <div>
              <h4>Internet Game Database (IGDB)</h4>
              <ExternalLink
                url={`${cachedSelectedGame.url}`}
                label={`${cachedSelectedGame.name}`}
                icon="link-external"
              />
              <Box p={2}></Box>
              <SubtleText>
                Updated{' '}
                {formatDistance(
                  buildTodayZero(),
                  new Date(cachedSelectedGame.updated)
                )}{' '}
                ago
              </SubtleText>
            </div>
          </Grid>
          <Grid item xs={5}>
            <GameAdminControls game={cachedSelectedGame} />
            <HLine label={`Picks (${totalPicks} total)`} />
            <GamePicksList game={cachedSelectedGame} />
            <Box p={4} />
            <HLine
              label={
                cachedSelectedGame.metacritic > 0
                  ? 'Predictions (' + totalPredictions + ' total)'
                  : 'Predictions'
              }
            />
            <Box p={1} />
            <GamePredictionsList game={cachedSelectedGame} />
            <UserPredictGame game={cachedSelectedGame} />
          </Grid>
        </Grid>
        <Box p={3} />
        {cachedSelectedGame.screenshots &&
          cachedSelectedGame.screenshots.length > 0 &&
          cachedSelectedGame.screenshots[0] && (
            <img
              src={buildIgdbImageLink(
                cachedSelectedGame.screenshots[0].image_id,
                'screenshot_med_2x'
              )}
              alt="game"
              style={{ width: '100%' }}
            />
          )}
        {cachedSelectedGame.screenshots &&
          cachedSelectedGame.screenshots.length > 0 &&
          cachedSelectedGame.screenshots
            .slice(1, cachedSelectedGame.screenshots.length)
            .map((shot, index) => {
              return (
                <img
                  key={index}
                  src={buildIgdbImageLink(shot.image_id, 'screenshot_med_2x')}
                  alt="game"
                  style={{
                    width: '50%',
                    height: '280px',
                    objectFit: 'cover',
                  }}
                />
              )
            })}
      </Breakpoint>
      {user?.admin && (
        <SubtleText>
          Game ID (hidden for players): {cachedSelectedGame.id}
        </SubtleText>
      )}
    </div>
  )
}
